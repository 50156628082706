<template>
  <strong>{{ getDestinatary() }}</strong>
</template>

<script>
import get from "lodash/get";
export default {
  name: "InvoicesTableDestinatary",
  methods: {
    getDestinatary() {
      return get(this.data, "i_to.name");
    },
  },
};
</script>
